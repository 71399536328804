/**
 * Const with params element
 * Block Article component
 * Display a block of summary article
 */
/* Import section */
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import Ctabutton from "./ctabutton"
import "../styles/components/blockarticle.scss"

/* Declaration function */
const Blockarticle = ({ titleArticle, srcImgArticle, altImgArticle, slugArticle, typeSlugArticle, }) => {
  return(
    <div className="block-article">
      <div className="box-article">
      <Link to={`/${typeSlugArticle}/${slugArticle}/`}><GatsbyImage image={srcImgArticle} alt={`picture ${altImgArticle}`} /></Link>
        <h2><Link to={`/${typeSlugArticle}/${slugArticle}/`}>{titleArticle}</Link></h2>
        <div className="box-readmore">
          <div className="button-readmore">
            <Ctabutton ctaLabel="Read More" ctaLink={`/${typeSlugArticle}/${slugArticle}`} otherStyle="bluemarin-format" />
          </div>
        </div>
      </div>
    </div>
  )
}

/* Export function */
export default Blockarticle

