/**
 * Blog list Template
 */
/* Import section */
import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React, { Component } from "react"

import Blockarticle from "../components/blockarticle"
import Ctabutton from "../components/ctabutton"
import Layout from "../components/layout"
import Sectionctafooter from "../components/sectionctafooter"
import Seo from "../components/seo"
import "../styles/templates/bloglist.scss"

/* Declaration class */
class PostListTemplate extends Component {

  constructor(props) {
    super(props)
    this.state = {
      currentUrl:''
    }
  }

  componentDidMount() {
    this.setState({ currentUrl: window.location.pathname })
  }

  render () {
    const { data } = this.props
    const { slug } = this.props.pageContext
    const dataLastPost = data.lastPost
    const dataAllPost = data.allPost

    //CTA Footer page FAQ
    var ctaFooterFAQ= data.options.acfPageOptions.repeaterCtaFooter.filter(function (ctaFooter) {
      return ctaFooter.typeCtaFooter === "faq"
    })

    return (
      <Layout>
        <Seo
          title={dataLastPost.edges[0].node.categories.nodes[0].seo.title}
          description={dataLastPost.edges[0].node.categories.nodes[0].seo.metaDesc}
        />

        {/* H1 header section */}
        <section className="section-hero-blog">
          <div className="hero-blog">
            <div className="title-hero-blog">
              <h1><Link to={`/blog/${dataLastPost.edges[0].node.slug}/`}>{dataLastPost.edges[0].node.title}</Link></h1>
              <div className="cta-last-blog">
                <Ctabutton ctaLabel="Read More" ctaLink={`/blog/${dataLastPost.edges[0].node.slug}`} otherStyle="bluemarin-format" />
              </div>
            </div>
            <div className="picture-hero-blog">
              <Link to={`/blog/${dataLastPost.edges[0].node.slug}/`}>
                <GatsbyImage image={dataLastPost.edges[0].node.acfPostBlogNews.imageArticle.localFile.childImageSharp.gatsbyImageData} alt={dataLastPost.edges[0].node.acfPostBlogNews.imageArticle.altText} />
              </Link>
              <div className="bckg-picture"></div>
            </div>
          </div>
          <div className="blue-banner"></div>
        </section>

        {/* Section with loop on all articles */}
        <section className="section-loop-article">
          <div className="box-loop-article">
            {dataAllPost.edges.map(({ node }) => (
              <Blockarticle
                key={node.databaseId}
                srcImgArticle={node.acfPostBlogNews.imageArticle.localFile.childImageSharp.gatsbyImageData}
                altImgArticle={node.acfPostBlogNews.imageArticle.altText}
                titleArticle={node.title}
                leadArticle={node.acfPostBlogNews.leadArticle}
                slugArticle={node.slug}
                typeSlugArticle={slug}
              />
            ))}
          </div>
        </section>

        {/* Section CtaFooter */}
        {ctaFooterFAQ.map(function (ctafooter, i) {
          return <Sectionctafooter key={i} typeCta="faq" titleCta={ctafooter.titleCtaFooter} labelCta={ctafooter.labelCtaFooter} classCta="bluegreyless" />
        })}
      </Layout>
    )
  }
}

/* Export template informations */
export default PostListTemplate

//QUERY GRAPHSQL ON POST
export const query = graphql`
  query($slug: String!) {
    allPost: allWpPost(filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}, sort: {fields: [date], order:DESC}, skip: 1) {
      edges {
        node {
          databaseId
          title
          slug
          acfPostBlogNews {
            leadArticle
            imageArticle{
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData (layout: CONSTRAINED, width: 365)
                }
              }
            }
          }
        }
      }
    }
    lastPost: allWpPost(limit: 1, filter: {categories: {nodes: {elemMatch: {slug: {eq: $slug}}}}}, sort: {fields: [date], order:DESC}) {
      edges {
        node {
          title
          slug
          categories {
            nodes {
              seo {
                metaDesc
                title
              }
            }
          }
          acfPostBlogNews {
            leadArticle
            imageArticle{
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData (layout: CONSTRAINED, width: 750)
                }
              }
            }
          }
        }
      }
    }
    options: wpPage(slug: {eq: "options-website"}) {
      acfPageOptions {
        repeaterCtaFooter {
          typeCtaFooter
          titleCtaFooter
          labelCtaFooter
        }
      }
    }
  }
`
